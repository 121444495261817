import axios from 'axios';

const apiRequest = axios.create({
    baseURL: 'api'
});

const apiLineRequest = axios.create({
    baseURL: '/oauth2'
});




export const GetTeacherList = data => apiRequest.get('/Teachers/GetTeacher', data);
export const GetBlogerList = data => apiRequest.get('/Bloggers', data);
export const GetMoonList = data => apiRequest.get('/Moons', data);
export const DeleteMoon = (data) => apiRequest.delete('/Moons/' + data);
export const PostMoon = (data) => apiRequest.post('/Moons/MoonAdd?SKey=027509631qaz@WSX', data);
export const LineGetToken = (data) => apiLineRequest.post('/v2.1/authorize', data);
export const GetShamathaList = data => apiRequest.get('/Shamathas', data);
export const DeleteShamatha = (data) => apiRequest.delete('/Shamathas/' + data);
export const PostShamatha = (data) => apiRequest.post('/Shamathas/ShamathaAdd?SKey=027509631qaz@WSX', data);